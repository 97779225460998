export default {
  ChangePassword: {
    Title: 'Изменить пароль',
    CurrentPasswordLabel: 'Текущий пароль',
    CurrentPasswordPlaceholder: 'Пожалуйста, введите ваш пароль',
    NewPasswordLabel: 'Новый пароль',
    NewPasswordPlaceholder: 'Пожалуйста введите новый пароль',
    RetypeNewPasswordLabel: 'Повторите новый пароль',
    RetypeNewPasswordPlaceholder: 'Пожалуйста, повторите новый пароль',
    SaveBtn: 'Сохранить изменения',
    ResetBtn: 'Сбросить',
  },
  PricingPlans: {
    Title: 'Тарифные планы',
    Description:
      '"1 RP (request point) равна 1 дню в отчете. Например, чтобы сформировать отчет за 7 дней, вам понадобится 7 RP".',
    Free: 'Простой старт для каждого',
    Basic: 'Простой старт для каждого',
    Standard: 'Для малого и среднего бизнеса',
    Premimum: 'Решение для больших организаций',
  },
  Login: {
    Title: 'Вход',
    Subtitle: 'Пожалуйста, зарегистрируйте ваш аккаунт и начните приключение',
    LoginBtn: 'Войти',
    PasswordLabel: 'Пароль',
    RememberMe: 'Запомнить меня',
    ForgotPassword: 'Забыли пароль?',
    NewOnPlatform: 'Новый участник платформы?',
    CreateAccountBtn: 'Создать аккаунт',
    AllRightsReserved: 'Все права сохранены',
  },
  Register: {
    CreateAccount: 'Создать аккаунт',
    PleaseSignin: 'Пожалуйста, войдите в свой аккаунт чтобы начать приключение',
    SigninAccount: 'Войти',
    Password: 'Пароль',
    ConfirmPassword: 'Подтвердите пароль',
    ConfirmPasswordPlaceholder: 'Пожалуйста, подтвердите пароль',
    AlreadyHaveAccount: 'У вас уже есть аккаунт?',
    SubmitButton: 'Создать аккаунт',
    AllRightsReserved: 'Все права сохранены',
  },
  ForgotPassword: {
    ForgotPassword: 'Забыли пароль?',
    ResetPasswordInstructions:
      'Введите свой адрес электронной почты и мы пришлем вам инструкции по сбросу пароля',
    SendResetLink: 'Отправить инструкцию',
    BackToLogin: 'Вернуться на страницу входа',
    AllRightsReserved: 'Все права сохранены',
  },
  SetNewPassword: {
    Title: 'Установите новый пароль',
    Subtitle:
      'Ваш новый пароль должен отличаться от ранее используемых паролей',
    NewPasswordLabel: 'Новый пароль',
    ConfirmPasswordLabel: 'Подтвердите пароль',
    SubmitButtonLabel: 'Установить новый пароль',
    AllRightsReserved: 'Все права сохранены.',
  },
  SelectYourPaymentMethod: {
    Title: 'Пожалуйста, выберите удобный способ оплаты',
    FromAddressFieldPlaceholder: 'С адреса',
    PayBtn: 'Оплатить {amount} USDT',
  },
  Payment: {
    Title: 'Отлично! Переведите средства на этот кошелек',
    EnsureNetworkMessage: 'Убедитесь, что это сеть',
    SelectedPayment: 'Выбранный способ оплаты',
    TotalToPay: 'Всего к оплате',
    PaymentInstruction:
      'Пополнение счета будет произведено правильно, если вся сумма будет оплачена одной транзакцией',
    Status: 'Статус:',
    Statuses: {
      COMPLETED: 'ЗАВЕРШЁН',
      PENDING: 'В ОЖИДАНИИ ПЛАТЕЖА',
      FAILED: 'СРОК ОПЛАТЫ ИСТЁК',
    },
    RefreshBtn: 'Обновить',
  },
  PaymentHistoryPage: {
    Title: 'История платежей',
    RefreshBtn: 'Обновить',
    Statuses: {
      COMPLETED: 'ЗАВЕРШЕНО',
      PENDING: 'В ПРОЦЕССЕ',
      EXPIRED: 'СРОК ОПЛАТЫ ИСТЁК',
    },
    Columns: {
      TransactionID: 'ID транзакции',
      PayAddress: 'Адрес оплаты',
      Plan: 'План',
      PayAmount: 'Оплачено',
      PayTxId: 'ID транзакции оплаты',
      RequestPoints: 'Очки запроса',
      Status: 'Статус',
      CreatedTime: 'Время создания',
    },
  },
  TxCostReportPage: {
    Title: 'Выписки из кошелька',
    GenerateReport: 'Сгенерировать заявления',
    ClearSearch: 'Очистить поиск',
    Search: 'Поиск',
    Refresh: 'Обновить',
    LoadMore: 'Подгрузить больше',
    Reset: 'Сбросить',
    GenerateReportButton: 'Генерация заявлений',
    TableColumns: {
      ReportId: 'ID отчёта',
      Coin: 'Валюта',
      RequestTime: 'Время запроса',
      SpentRPs: 'Потрачено RP',
      Status: 'Статус',
    },
    Statuses: {
      COMPLETED: 'ЗАВЕРШЁН',
      HOLD: 'ДЕРЖАТЬ',
      PENDING: 'В ПРОЦЕССЕ',
      FAILED: 'ОШИБКА',
    },
    NoData: 'Нет данных по транзакциям',
  },
  TxCostReportFilterModal: {
    Title: 'Поиск',
    ReportIdLabel: 'ID отчета',
    ReportIdPlaceholder: 'Введите ID отчета',
    CoinTypeLabel: 'Валюта',
    CoinTypePlaceholder: 'Выберите валюту',
    StartTimeLabel: 'Время начала запроса',
    StartTimePlaceholder: 'Выберите время начала',
    EndTimeLabel: 'Время окончания запроса',
    EndTimePlaceholder: 'Выберите время окончания',
    StatusLabel: 'Статус',
    StatusPlaceholder: 'Выберите статус',
    SearchBtn: 'Поиск',
    StatusOptions: {
      COMPLETED: 'ЗАВЕРШЕН',
      PENDING: 'В ПРОЦЕССЕ',
      HOLD: 'ЗАДЕРЖАНА',
      FAILED: 'ОШИБКА',
    },
  },
  SearchTransactionDetails: {
    Title: 'Поиск деталей транзакции',
    SearchFiledPlaceholder: 'Поиск по хешу транзакции',
    SearchBtn: 'Поиск',
    RPsRequired: '{amount} RP необходимо',
    TransactionDetails: 'Подробности транзакции',
    Hash: 'Хеш',
    TxStatus: 'Статус',
    Network: 'Сеть',
    Fees: 'Комиссия',
    Time: 'Время',
    Overview: 'Обзор',
    From: 'От',
    To: 'На',
    TransactionNotFound: 'Транзакция не найдена',
  },
  BalanceReport: {
    Title: 'Баланс кошелька',
    CreateBalanceReportBtn: 'Получить баланс',
    ClearSearch: 'Очистить поиск',
    LoadMore: 'Подгрузить больше',
  },
  BalanceReportForm: {
    CurrencyLabel: 'Валюта',
    WalletAddressLabel: 'Адрес кошелька',
    RequestPointsRequired: 'Цена: {requestPointsAmount} RP',
    RequestPointsRequiredMobile: '(Стоимость {requestPointsAmount} RP)',
  },
  BalanceReportHistory: {
    Title: 'История запросов',
    Columns: {
      Id: 'ID',
      Coin: 'Валюта',
      RequestTime: 'Дата запроса',
      SpentRPs: 'Потрачено RPs',
      Status: 'Статус',
    },
    LoadMoreBtn: 'Подгрузить больше',
    NoData: 'Похоже, что у вас еще нет отчетов о балансе',
  },
  BalanceReportRequestDetails: {
    Columns: {
      Currency: 'Валюта',
      Amount: 'Баланс',
    },
    LoadMoreBtn: 'Показать ещё',
  },
  CreateWallet: {
    Title: 'Создать кошелёк',
    CreateWalletBtn: 'Создать',
    WalletDetails: 'Детали кошелька',
    Address: 'Адрес',
    PublicKey: 'Публичный ключ',
    PrivateKey: 'Приватный ключ',
    Clear: 'Очистить',
    CoinTypePlaceholder: 'Выберите валюту',
    RPsRequired: '{amount} RP необходимо',
  },
  GenerateTxCostReport: {
    NetworkCurrency: 'Валюта сети',
    ReportCurrency: 'Валюта отчёта',
    Close: 'Закрыть',
    GenerateReport: 'Генерация заявлений',
    Address: 'Адрес',
    StartTimePlaceholder: 'Пожалуйста, выберите начальное время',
    EndTimePlaceholder: 'Пожалуйста, выберите конечное время',
    StartTime: 'Начальная дата',
    EndTime: 'Конечная дата',
    GenerateNewReports: 'Сгенерировать новый отчёт',
    AddToFavorites: 'Добавить в избранное',
  },
  BreadcrumbLabels: {
    Titles: {
      Home: 'Домашняя страница',
      TxCostReport: 'Выписки из кошелька',
      BalanceReport: 'Баланс кошелька',
      CreateWallet: 'Создание кошелька',
      TransactionDetails: 'Поиск транзакций',
      Pricing: 'Тариф',
      ChangePassword: 'Изменить пароль',
      PaymentMethod: 'Способ оплаты',
      Payment: 'Оплата',
      PaymentList: 'История платежей',
      GenerateTxCostReport: 'Генерация заявлений',
      MonitorAddress: 'Адрес монитора',
      AddMonitorAddress: 'Добавить адрес монитора',
      EditMonitorAddress: 'Изменить адрес монитора',
    },
    PathLabels: {
      Home: 'Домашняя страница',
      TxCostReport: 'Выписки из кошелька',
      BalanceReport: 'Проверка баланса кошелька',
      CreateWallet: 'Создание кошелька',
      TransactionDetails: 'Поиск межблокчейновых транзакций',
      Pricing: 'Тариф',
      ChangePassword: 'Изменить пароль',
      PaymentMethod: 'Способ оплаты',
      Payment: 'Оплата',
      PaymentList: 'История платежей',
      GenerateTxCostReport: 'Генерация заявлений',
      MonitorAddress: 'Адрес монитора',
      AddMonitorAddress: 'Добавить адрес монитора',
      EditMonitorAddress: 'Изменить адрес монитора',
    },
  },
  CoinCard: {
    Buy: 'Купить',
  },
  NavbarDropdown: {
    PaymentHistory: 'История платежей',
    Security: 'Безопасность',
    Logout: 'Выход',
  },
  ConfirMationModule: {
    AreYouSure: 'Вы уверены',
    Cancel: 'Отмена',
    InsufficientRP: 'Недостаточно Rp',
    Buy: 'Купить',
    Download: 'Скачать',
  },
  Home: {
    FullTxCostReport: {
      Title: 'Выписки из кошелька',
      Description:
        'Получите отчет о транзакциях вашего адреса за любой период времени с фиатными ценностями. Сделайте налоговую отчетность и бухгалтерский учет менее хлопотными.',
      Button: 'Перейти к отчету',
    },
    SearchByTxHash: {
      Title: 'Поиск межблокчейновых транзакций',
      Description:
        'Легко ищите и исследуйте транзакции в нескольких блокчейнах, раскрывайте подробную информацию о транзакциях.',
      Button: 'Перейти к экрану поиска',
    },
    BalanceReport: {
      Title: 'Проверка баланса кошелька',
      Description:
        'Получите полную картину баланса вашего кошелька в режиме реального времени.',
      Button: 'Перейти к экрану проверки баланса',
    },
    CreateWallet: {
      Title: 'Создать кошелёк',
      Description:
        'Создайте свой кошелек и сохраните полный контроль над своим закрытым ключом',
      Button: 'Перейти к созданию кошелька',
    },
    MonitorAddress: {
      Title: 'Адреса мониторинга',
      Description:
        'Будьте в курсе с уведомлениями о монетах в реальном времени для вашего кошелька.',
      Button: 'Перейти к экрану адреса монитора',
    },
    MonitorAddress: {
      Title: 'Адрес монитора',
      AddAddress: 'Добавить адрес',
      NoData: 'Нет данных',
      LoadMore: 'Загрузи больше',
      SearchFiledPlaceholder: 'Поиск по адресу транзакции',
      SearchBtn: 'Поиск',
      TableColumns: {
        ReportId: 'Идентификатор отчета',
        Coin: 'Монета',
        Address: 'Адрес',
        Url: 'Url',
        Action: 'Действие',
      },
      successful: {
        AddressDeletedSuccessfully: 'Адрес успешно удален',
      },
    },
    AddMonitorAddressReport: {
      Close: 'Закрыть',
      AddAddress: 'Добавить адрес',
      Url: 'Url',
      Address: 'Адрес',
      NetworkCurrency: 'Валюта сети',
      Key: 'Ключ',
      Value: 'Цена',
      HeaderName: 'Название заголовка',
      RPsRequired: 'В день {amount} RP необходимо',
      successful: {
        AddressAddedSuccessfully: 'Адрес успешно добавлен',
      },
      errorMessage: {
        headerNameRequired: 'Укажите название заголовка.',
        headerValueRequired: 'Укажите значение заголовка.',
        headerNameShouldBeUnique: 'Имя заголовка должно быть уникальным',
      },
    },
    SearchComponent: {
      SearchFiledPlaceholder: 'Выберите или введите адрес',
    },
  },
};
