export default {
  ChangePassword: {
    Title: 'Cambiar contraseña',
    CurrentPasswordLabel: 'Contraseña actual',
    CurrentPasswordPlaceholder: 'Ingrese su contraseña',
    NewPasswordLabel: 'Nueva contraseña',
    NewPasswordPlaceholder: 'Ingrese su nueva contraseña',
    RetypeNewPasswordLabel: 'Vuelva a escribir la nueva contraseña',
    RetypeNewPasswordPlaceholder: 'Repita la nueva contraseña',
    SaveBtn: 'Guardar los cambios',
    ResetBtn: 'Reiniciar',
  },
  PricingPlans: {
    Title: 'Planes de precios',
    Description:
      '"1 RP (request point) es igual a 1 día en el extracto. Por ejemplo, para generar un informe de 7 días, necesita 7 RP".',
    Free: 'Un comienzo simple para todos',
    Basic: 'Un comienzo simple para todos',
    Standard: 'Para pequeñas y medianas empresas',
    Premimum: 'Solución para grandes organizaciones',
  },
  Login: {
    Title: 'Iniciar sesión',
    Subtitle: 'Inicie sesión en su cuenta y comience la aventura',
    LoginBtn: 'Iniciar sesión',
    PasswordLabel: 'Contraseña',
    RememberMe: 'Recuérdeme',
    ForgotPassword: '¿Olvidó su contraseña?',
    NewOnPlatform: '¿Nuevo en la plataforma?',
    CreateAccountBtn: 'Crear una cuenta',
    AllRightsReserved: 'Todos los derechos reservados',
  },
  Register: {
    CreateAccount: 'Crear la cuenta',
    PleaseSignin: 'Inicie sesión en su cuenta y comience la aventura',
    SigninAccount: 'Iniciar sesión en lugar de',
    Password: 'Contraseña',
    ConfirmPassword: 'Confirmar contraseña',
    ConfirmPasswordPlaceholder: 'Confirme su contraseña',
    AlreadyHaveAccount: '¿Ya tiene una cuenta?',
    SubmitButton: 'Crear la cuenta',
    AllRightsReserved: 'Todos los derechos reservados',
  },
  ForgotPassword: {
    ForgotPassword: 'Olvidé mi contraseña',
    ResetPasswordInstructions:
      "Enter your email and we'll send you instructions to reset your password",
    SendResetLink: 'Enviar enlace de restablecimiento',
    BackToLogin: 'Volver al inicio de sesión',
    AllRightsReserved: 'Todos los derechos reservados',
  },
  SetNewPassword: {
    Title: 'Establecer nueva contraseña',
    Subtitle:
      'Su nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente',
    NewPasswordLabel: 'Nueva contraseña',
    ConfirmPasswordLabel: 'Confirmar contraseña',
    SubmitButtonLabel: 'Establecer nueva contraseña',
    AllRightsReserved: 'Todos los derechos reservados.',
  },
  SelectYourPaymentMethod: {
    Title: 'Seleccione su método de pago',
    FromAddressFieldPlaceholder: 'Desde la dirección',
    PayBtn: 'Pagar {monto} USDT',
  },
  Payment: {
    Title: '¡Excelente! Transferir fondos a esta billetera',
    EnsureNetworkMessage: 'Asegúrese de que la red esté',
    SelectedPayment: 'Tipo de pago seleccionado',
    TotalToPay: 'Total a pagar',
    PaymentInstruction:
      'La recarga se realizará correctamente cuando toda el monto se pague en una sola transacción',
    Status: 'Estado:',
    Statuses: {
      COMPLETED: 'COMPLETADO',
      PENDING: 'PENDIENTE',
      FAILED: 'CADUCÓ',
    },
    RefreshBtn: 'Refrescar',
  },
  PaymentHistoryPage: {
    Title: 'HISTORIAL DE PAGOS',
    RefreshBtn: 'Refrescar',
    Statuses: {
      COMPLETED: 'COMPLETADO',
      PENDING: 'PENDIENTE',
      EXPIRED: 'CADUCÓ',
    },
    Columns: {
      TransactionID: 'Identificación de la transacción',
      PayAddress: 'Dirección de pago',
      Plan: 'Planificar',
      PayAmount: 'Monto de pago',
      PayTxId: 'Identificación de Pay Tx',
      RequestPoints: 'Solicitar puntos',
      Status: 'Estado',
      CreatedTime: 'Hora de creación',
    },
  },
  TxCostReportPage: {
    Title: 'Estados de cuenta de la billetera',
    GenerateReport: 'Generar declaraciones',
    ClearSearch: 'Borrar búsqueda',
    Search: 'Buscar',
    Refresh: 'Refrescar',
    LoadMore: 'Cargar más',
    Reset: 'Reiniciar',
    GenerateReportButton: 'Estados de cuenta de la billetera',

    TableColumns: {
      ReportId: 'Identificación del informe',
      Coin: 'Moneda',
      RequestTime: 'Solicitar tiempo',
      SpentRPs: 'RP gastados',
      Status: 'Estado',
    },
    Statuses: {
      COMPLETED: 'COMPLETADO',
      PENDING: 'PENDIENTE',
      HOLD: 'ESPERAR',
      FAILED: 'FALLÓ',
    },
    NoData: 'No hay datos para transacciones',
  },
  TxCostReportFilterModal: {
    Title: 'Buscar',
    ReportIdLabel: 'Identificación del informe',
    ReportIdPlaceholder: 'Ingrese la identificación del informe',
    CoinTypeLabel: 'Tipo de moneda',
    CoinTypePlaceholder: 'Seleccione el tipo de moneda',
    StartTimeLabel: 'Hora de inicio de la solicitud',
    StartTimePlaceholder: 'Seleccione la hora de inicio',
    EndTimeLabel: 'Hora de finalización de la solicitud',
    EndTimePlaceholder: 'Seleccione la hora de finalización',
    StatusLabel: 'Estado',
    StatusPlaceholder: 'Seleccionar estado',
    SearchBtn: 'Buscar',
    StatusOptions: {
      COMPLETED: 'COMPLETADO',
      PENDING: 'PENDIENTE',
      HOLD: 'ESPERAR',
      FAILED: 'FALLÓ',
    },
  },
  SearchTransactionDetails: {
    Title: 'Buscar detalles de transacción',
    SearchFiledPlaceholder: 'Buscar por hash de transacción',
    SearchBtn: 'Buscar',
    RPsRequired: 'Se requieren {amount} puntos de solicitud',
    TransactionDetails: 'Detalles de la transacción',
    Hash: 'Hash',
    TxStatus: 'Estado de Tx',
    Network: 'Red',
    Fees: 'Comisiones',
    Time: 'Tiempo',
    Overview: 'Resumen',
    From: 'От',
    To: 'На',
    TransactionNotFound: 'Transacción no encontrada',
  },
  BalanceReport: {
    Title: 'Saldo de la billetera',
    ClearSearch: 'Borrar búsqueda',
    CreateBalanceReportBtn: 'Ver el saldo',
    LoadMore: 'Cargar más',
  },
  BalanceReportForm: {
    CurrencyLabel: 'Tipo de moneda',
    WalletAddressLabel: 'Dirección',
    RequestPointsRequired: 'Costo {requestPointsAmount} RP requerido',
    RequestPointsRequiredMobile: '(Costo {requestPointsAmount} RP)',
  },
  BalanceReportHistory: {
    Title: 'Historial de solicitudes',
    Columns: {
      Id: 'Identificación',
      Coin: 'Moneda',
      RequestTime: 'Solicitar tiempo',
      SpentRPs: 'RP gastados',
      Status: 'Estado',
    },
    LoadMoreBtn: 'Cargar más',
    NoData: "It looks like you don't have generated balance reports yet",
  },
  BalanceReportRequestDetails: {
    Columns: {
      Currency: 'Divisa',
      Amount: 'Cantidad',
    },
    LoadMoreBtn: 'Cargar más',
  },
  CreateWallet: {
    Title: 'Crear billetera',
    CreateWalletBtn: 'Crear',
    WalletDetails: 'Detalles de la billetera',
    Address: 'Dirección',
    PublicKey: 'Clave pública',
    PrivateKey: 'Clave privada',
    Clear: 'Limpiar',
    CoinTypePlaceholder: 'Seleccione el tipo de moneda',
    RPsRequired: 'Se requieren {amount} puntos de solicitud',
  },
  GenerateTxCostReport: {
    NetworkCurrency: 'Divisa de la red',
    ReportCurrency: 'Divisa del informe',
    Close: 'Cerrar',
    GenerateReport: 'Generar declaraciones',
    Address: 'Dirección',
    StartTimePlaceholder: 'Seleccione la hora de inicio',
    EndTimePlaceholder: 'Seleccione la hora de finalización',
    StartTime: 'Hora de inicio',
    EndTime: 'Hora de finalización',
    GenerateNewReports: 'Generar informes nuevos',
    AddToFavorites: 'Agregar a favoritos',
  },
  BreadcrumbLabels: {
    Titles: {
      Home: 'Inicio',
      TxCostReport: 'Estados de cuenta de la billetera',
      BalanceReport: 'Saldo de la billetera',
      CreateWallet: 'Crear billetera',
      TransactionDetails: 'Búsqueda de transacciones',
      Pricing: 'Precios',
      ChangePassword: 'Cambiar contraseña',
      PaymentMethod: 'Método de pago',
      Payment: 'Pago',
      PaymentList: 'Lista de pagos',
      GenerateTxCostReport: 'Generar declaraciones',
      MonitorAddress: 'Dirección de monitor',
      AddMonitorAddress: 'Agregar dirección de monitor',
      EditMonitorAddress: 'Editar dirección del monitor',
    },
    PathLabels: {
      Home: 'Inicio',
      TxCostReport: 'Estados de cuenta de la billetera',
      BalanceReport: 'Verificador de saldo de la billetera',
      CreateWallet: 'Crear billetera',
      TransactionDetails: 'Búsqueda de transacciones entre cadenas de bloques',
      Pricing: 'Precios',
      ChangePassword: 'Cambiar contraseña',
      PaymentMethod: 'Método de pago',
      Payment: 'Pago',
      PaymentList: 'Lista de pagos',
      GenerateTxCostReport: 'Generar declaraciones',
      MonitorAddress: 'Dirección de monitor',
      AddMonitorAddress: 'Agregar dirección de monitor',
      EditMonitorAddress: 'Editar dirección del monitor',
    },
  },
  CoinCard: {
    Buy: 'Comprar',
  },

  NavbarDropdown: {
    PaymentHistory: 'Historial de pagos',
    Security: 'Seguridad',
    Logout: 'Cerrar sesión',
  },
  ConfirMationModule: {
    AreYouSure: 'Estas seguro',
    Cancel: 'Cancelar',
    InsufficientRP: "Insufficient Rp's",
    Buy: 'Comprar',
    Download: 'Descargar',
  },
  Home: {
    FullTxCostReport: {
      Title: 'Estados de cuenta de la billetera',
      Description:
        'Obtenga un informe con valores fiduciarios sobre las transacciones de su dirección para cualquier período de tiempo. Haga que su declaración de impuestos y su contabilidad sean menos complicadas.',
      Button: 'Ir a la pantalla de informes',
    },
    SearchByTxHash: {
      Title: 'Búsqueda de transacciones entre cadenas de bloques',
      Description:
        'Busque y explore sin esfuerzo transacciones en varias cadenas de bloques y descubra información detallada sobre las transacciones.',
      Button: 'Ir a la pantalla de búsqueda',
    },
    BalanceReport: {
      Title: 'Verificador de saldo de la billetera',
      Description:
        "Get a complete picture of your wallet's real-time balances.",
      Button: 'Ir a la pantalla del verificador de saldo',
    },

    CreateWallet: {
      Title: 'Crear billetera',
      Description:
        'Genere su billetera y conserve el control total de su clave privada',
      Button: 'Ir a la pantalla de creación de billetera',
    },
    MonitorAddress: {
      Title: 'Dirección de monitor',
      Description:
        'Manténgase informado con notificaciones de monedas en tiempo real para su billetera.',
      Button: 'Ir a la pantalla de dirección del monitor',
    },
  },
  MonitorAddress: {
    Title: 'Dirección de monitor',
    AddAddress: 'Agregar dirección',
    NoData: 'Sin datos',
    LoadMore: 'Cargar más',
    SearchFiledPlaceholder: 'Buscar por dirección de transacción',
    SearchBtn: 'Buscar',
    TableColumns: {
      ReportId: 'Identificación del informe',
      Coin: 'Moneda',
      Address: 'Dirección',
      Url: 'URL',
      Action: 'Acción',
    },
    successful: {
      AddressDeletedSuccessfully: 'Dirección eliminada con éxito',
    },
  },
  AddMonitorAddressReport: {
    Close: 'Cerrar',
    AddAddress: 'Agregar dirección',
    Url: 'URL',
    Address: 'Dirección',
    NetworkCurrency: 'Divisa de la red',
    Key: 'Clave',
    Value: 'Valor',
    HeaderName: 'Nombre del encabezado',
    RPsRequired: 'Se requieren {amount} puntos de solicitud por día',
    successful: {
      AddressAddedSuccessfully: 'Dirección agregada con éxito',
    },
    errorMessage: {
      headerNameRequired: 'El nombre del encabezado es obligatorio',
      headerValueRequired: 'El valor del encabezado es obligatorio',
      headerNameShouldBeUnique: 'El nombre del encabezado debe ser único',
    },
  },
  SearchComponent: {
    SearchFiledPlaceholder: 'Seleccione o escriba una dirección',
  },
};
